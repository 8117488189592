import { mdiClose, mdiFileDocument, mdiFilePdfBox, mdiFilePlusOutline, mdiImage, mdiMusicBox } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { Formik } from "formik";
import * as yup from "yup";


function AddNoteDialog({ onClose, open, type, dataId, handleReloadFunc, editId }) {

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [noteList, setNoteList] = useState([])

    const [selectedFiles, setSelectedFiles] = useState([]);
    //const [fileErrors, setFileErrors] = useState([]);


    //=======Start::Write a code for manage Edit Notes======//
    useEffect(() => {
        if (editId) {
            const fetchData = async () => {
                await getNotes();
            };
            fetchData();
        }
    }, [editId]);

    async function getNotes() {
        showLoader()
        await axiosInstance.get('api/' + type + '-note/edit/' + editId + '?' + type + 'Id=' + dataId).then(response => {
            if (response.data.code === 200) {
                setNoteList(response.data.data.record);
            }
            hideLoader()
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        });
    }
    //=======Start::Write a code for manage Edit Notes======//


    const iconMapping = {
        image: mdiImage,
        audio: mdiMusicBox,
        file: mdiFileDocument,
        pdf: mdiFilePdfBox,
        // Add more mappings as needed
    };

    const allowedMimeTypes = [
        'image/jpeg', 'image/png', 'image/gif',
        'video/mp4', 'video/quicktime', 'video/x-msvideo',
        'video/x-ms-wmv', 'video/x-matroska', 'audio/mpeg',
        'audio/ogg', 'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.presentation', 'text/csv', 'application/pdf',
        'text/plain', 'application/vnd.ms-office', 'text/x-c', 'text/anytext', 'application/ogg', 'application/x-ogg',
        'text/comma-separated-values', 'application/x-ogg', 'text/anytext'
    ];

    const validationSchema = yup.object().shape({
        notes: yup
            .string()
            .required("Enter title")
            .min(2, "The name must be at least 2 characters.")
            .max(50, "The name must not be greater than 50 characters."),
        description: yup
            .string()
            .min(2, "The description must be at least 2 characters.")
            .max(150, "The description must not be greater than 150 characters."),
        file_url: yup
            .array()
            .nullable()
            .of(
                yup
                    .mixed()
                    .test('fileFormat', 'Unsupported file format. Please upload a valid file type.', (value) => {
                        return value && allowedMimeTypes.includes(value.type);
                    })
                    .test('fileSize', 'File size exceeds 4 MB', (value) => {
                        return value && value.size <= 4 * 1024 * 1024;
                    })
            )
            //.required('Please select at least one file')
            .test('validFiles', 'Files are not uploaded due to validation errors', function (value) {
                if (value) {
                    return value.every(file => {
                        const isValidFormat = allowedMimeTypes.includes(file.type);
                        const isValidSize = file.size <= 4 * 1024 * 1024;
                        return isValidFormat && isValidSize;
                    });
                }
                return true;
            }),
    });

    const handleFileChange = (event) => {
        const files = Array.from(event.currentTarget.files);
        const errors = [];
        const validFiles = [];

        const allowedMimeTypes = [
            'image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime',
            'video/x-msvideo', 'video/x-ms-wmv', 'video/x-matroska', 'audio/mpeg',
            'audio/ogg', 'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.presentation', 'text/csv', 'application/pdf',
            'text/plain', 'application/vnd.ms-office', 'text/x-c', 'text/anytext',
            'application/ogg', 'application/x-ogg', 'text/comma-separated-values',
            'application/x-ogg', 'text/anytext'
        ];

        files.forEach((file) => {
            if (file.size > 4 * 1024 * 1024) {
                errors.push(`${file.name} exceeds 4MB size limit`);
            } else if (!allowedMimeTypes.includes(file.type)) {
                errors.push(`${file.name} is an invalid file type`);
            } else {
                validFiles.push(file);
            }
        });

        if (errors.length > 0) {
            showSnackbar(`${errors.length} file(s) not uploaded due to validation errors`, 'error');
        } else {
            //showSnackbar(`${validFiles.length} file(s) are added successfully`, 'success');
        }

        setSelectedFiles([...selectedFiles, ...validFiles]);
        //setFileErrors(errors);
        event.target.value = ''
    };

    const removeFile = (index, setFieldValue, errors) => {
        // Remove file from selectedFiles array
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);

        // Remove the corresponding error from errors.file_url
        //const newErrors = [...errors.file_url];
        const newErrors = Array.isArray(errors.file_url) ? [...errors.file_url] : [];
        newErrors.splice(index, 1);

        // Update Formik's field value
        setFieldValue("file_url", newErrors);
    };

    const handleSubmit = async (values, onSubmitProps) => {
        // if (fileErrors.length > 0 || selectedFiles.length === 0) {
        //     setFileErrors(['Please select valid files.']);
        //     return;
        // }

        showLoader()
        const formData = new FormData();
        formData.append('leadId', dataId)
        formData.append('dealId', dataId)
        formData.append('notes', values.notes)
        formData.append('description', values.description)
        formData.append('type', 'Attachment')
        if (editId) {
            formData.append('id', editId)
        }

        for (var i = 0; i < selectedFiles.length; i++) {
            formData.append('file_url[]', selectedFiles[i])
        }

        await axiosInstance.post(`/api/${type}-note/${editId ? 'update' : 'store'}`, formData).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]); //handle multiple error on single fields remove this last [0]: errors[key][0]
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });

    }

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>{editId ? 'Edit' : 'Add'} Note</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        notes: noteList?.notes || '',
                        description: noteList?.description || '',
                        file_url: '',
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Title"
                                        variant="filled"
                                        name="notes"
                                        value={values.notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.notes && touched.notes}
                                        helperText={(errors.notes && touched.notes) && errors.notes}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Description"
                                        variant="filled"
                                        name="description"
                                        multiline
                                        rows={3}
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.description && touched.description}
                                        helperText={(errors.description && touched.description) && errors.description}
                                    />

                                </Grid>

                                <Grid item xs={12}>
                                    <div className="attachment-center">
                                        <div className="title">
                                            <Typography variant="title-medium-bold">Attachments</Typography> <Typography variant="title-small" className="file-size">(Max Size 4 MB)</Typography>
                                        </div>

                                        <div className="psuedo-upload-block">
                                            <div className="content">
                                                <Button color="secondary" className="btn-block" tabIndex={-1}>
                                                    <span>
                                                        <Icon path={mdiFilePlusOutline} color={'currentColor'} size={1} />
                                                        <span>Upload File</span>
                                                    </span>
                                                </Button>
                                            </div>
                                            <input
                                                type="file"
                                                multiple
                                                name="file_url"
                                                className="psuedo-upload-element"
                                                onChange={(event) => {
                                                    handleFileChange(event);
                                                    setFieldValue("file_url", Array.from(event.currentTarget.files));
                                                }}
                                                onBlur={handleBlur}
                                                accept=".jpeg,.png,.jpg,.gif,.svg,.pdf,.mp4,.mp3,.3gp,.avi,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.ods,.odt,.odp,.csv,.txt,.application/msword,.application/vnd.ms-powerpoint,.application/vnd.oasis.opendocument.text,.application/vnd.ms-excel,.application/vnd.openxmlformats-officedocument.wordprocessingml.document,.application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                                title="Allow file type are jpeg,png,jpg,gif,svg,pdf,mp4,mp3,3gpp,x-msvideo,xls,xlsx,doc,docx,ppt,pptx,ods,odt,odp,csv,anytext,plain,ms-office and MAX each file size are 4MB"
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                {selectedFiles && selectedFiles.length > 0 && <Grid container component={'ul'} spacing={1.5} className="attachments">
                                    {selectedFiles.map((attachment, index) => {
                                        const fileExtension = attachment && attachment?.name?.split('.').pop().toLowerCase();
                                        let fileType = '';
                                        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                                            fileType = 'image';
                                        } else if (['mp3', 'wav', 'ogg', 'mp4'].includes(fileExtension)) {
                                            fileType = 'audio';
                                        } else if (['pdf'].includes(fileExtension)) {
                                            fileType = 'pdf';
                                        } else {
                                            fileType = 'file';
                                        }
                                        const IconComponent = iconMapping[fileType];

                                        return (
                                            <Grid item component={'li'} xs={12} key={index + ''}>
                                                <a href={attachment.fileUrl} className={(Array.isArray(errors.file_url) && errors.file_url.length > 0 && errors.file_url[index]) ? 'has-error' : ''}>
                                                    <div className="icon-wrapper">
                                                        {IconComponent && <Icon path={IconComponent} color={'currentColor'} size={1} />}
                                                    </div>
                                                    <div className="info">
                                                        <Typography variant="body-large" component={'p'}>{attachment.name}</Typography>
                                                        {attachment.size && <Typography variant="body-medium">Size: {(attachment?.size / 1024 / 1024).toFixed(2)} MB</Typography>}
                                                    </div>
                                                </a>
                                                {selectedFiles && <Tooltip title="Remove">
                                                    <button type="button" className="icon-button size-small" onClick={() => removeFile(index, setFieldValue, errors)}>
                                                        <Icon path={mdiClose} color={'currentColor'} size={1} />
                                                    </button>
                                                </Tooltip>}
                                                {Array.isArray(errors.file_url) && errors.file_url[index] && (
                                                    <ul className='error-text'>
                                                        {Array.isArray(errors.file_url[index]) ? errors.file_url[index].map((error, idx) => (
                                                            <li key={idx}>{error}</li>
                                                        )) : <li>{errors.file_url[index]}</li>}
                                                    </ul>
                                                )}
                                            </Grid>
                                        )
                                    }
                                    )}
                                </Grid>}

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button className="btn-block" type="submit">{editId ? 'Edit' : 'Add'} Note</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog >
        </>
    );
}

export default AddNoteDialog;