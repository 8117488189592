import React, { useState } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, Stack, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import MyCustomNumberFormat from "../../../components/MyCustomNumberFormat";
import PercentageFormatCustom from "../../../components/MyCustomPercentageFormat";
import { displayDate } from "../../../utils/Utils";

function ConvertLeadToDealDialog({ onClose, open, handleReloadFunc, leadData }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, currencyCode, currencySymbol, currencyStringValue } = useSelector(state => state?.user);

    const lengthValidation = (fieldLength) => {
        var valid = true;
        if (fieldLength) {
            var str = fieldLength.replace(/\s/g, '')
            if (str.length < 2) {
                valid = false;
            }
        }

        return valid ? {
            isValid: true,
        } : {
            isValid: false,
            errorMessage: 'Must be at least 2 characters, no space allow',
        }
    }

    const validationSchema = yup.object().shape({
        deal_name: yup.string().trim('Deal name cannot include space').strict(true).required('Enter deal name').max(55, 'Must be at most 55 characters'),
        deal_amount: yup.string().required('Enter deal amount'),
        deal_description: yup.string().required('Enter deal description').min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters')
            .test('validator-custom-length', function (value) {
                const validation = lengthValidation(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                }
                else {
                    return true;
                }
            }),
        deal_probability: yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter deal probability').positive().max('100', "Please enter less than 100%")

    })

    const formikRef = React.useRef();


    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            lead_id: leadData?.id,
            deal_name: values?.deal_name,
            deal_description: values?.deal_description,
            deal_amount: values?.deal_amount,
            deal_probability: values?.deal_probability,
            expected_closing_date: values.expected_closing_date ? moment(values.expected_closing_date).format('YYYY-MM-DD') : '',
            latitude: '',
            longitude: '',
        }
        showLoader()
        await axiosInstance.post("/api/deal/convert", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Convert to Deal</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        deal_name: leadData?.dealName,
                        deal_amount: '',
                        deal_probability: '',
                        deal_description: '',
                        expected_closing_date: moment().add(2, 'days'),
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="filled"
                                            label='Contact Person'
                                            value={leadData?.name}
                                            readOnly
                                        //disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="filled"
                                            label='Company Name'
                                            value={leadData?.organization}
                                            readOnly
                                        //disabled
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Deal Name"
                                            variant="filled"
                                            name="deal_name"
                                            value={values?.deal_name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.deal_name && touched.deal_name}
                                            helperText={(errors.deal_name && touched.deal_name) && errors.deal_name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="filled"
                                            label='Deal Amount'
                                            id="deal_amount"
                                            name="deal_amount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter deal amount"
                                            value={values?.deal_amount || ''}
                                            InputProps={{
                                                inputComponent: MyCustomNumberFormat, //here last is input/text to display on page
                                                inputProps: { currencySymbol: currencySymbol, currencyCode: currencyCode, currencyStringValue: currencyStringValue, displayType: 'input' },//displayType=input/text
                                                endAdornment: <span className="text-adornment">{currencyCode}</span>
                                            }}
                                            error={errors.deal_amount && touched.deal_amount}
                                            helperText={(errors.deal_amount && touched.deal_amount) && errors.deal_amount}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Probability"
                                            variant="filled"
                                            name="deal_probability"
                                            value={values.deal_probability}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                                inputComponent: PercentageFormatCustom,
                                                endAdornment: <span className="text-adornment">%</span>
                                            }}
                                            error={errors.deal_probability && touched.deal_probability}
                                            helperText={(errors.deal_probability && touched.deal_probability) && errors.deal_probability}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label="Expected Closing Date"
                                                format={displayDate(preferredDateFormat, 'half')}
                                                views={['year', 'month', 'day']}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'filled',
                                                        name: "expected_closing_date",
                                                        type: 'text',
                                                        error: errors.expected_closing_date && touched.expected_closing_date,
                                                        helperText: (errors.expected_closing_date && touched.expected_closing_date) && errors.expected_closing_date
                                                    }
                                                }}
                                                value={values?.expected_closing_date || null}
                                                onChange={(value) => setFieldValue("expected_closing_date", value, true)}
                                                onBlur={handleBlur}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>

                                <TextField
                                    label="Description"
                                    variant="filled"
                                    multiline
                                    rows={3}
                                    name="deal_description"
                                    value={values?.deal_description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.deal_description && touched.deal_description}
                                    helperText={(errors.deal_description && touched.deal_description) && errors.deal_description}
                                />

                                <Stack direction="row" spacing={1.5} justifyContent={'flex-end'}>
                                    <Button type="submit">Convert</Button>
                                    <Button color="secondary" onClick={onClose}>Cancel</Button>
                                </Stack>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default ConvertLeadToDealDialog;